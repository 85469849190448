import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import SocketMinerApi from "../../../api/SocketMinerApi"
import * as ethers from 'ethers'
const { TextArea } = Input;

class SocketMiner extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showRegister:false,
            confirmLoading:false,
            showOnboard:false,
            statusList:[
                {value: "orderable", label: "已注册"},
                {value: "onboarded", label: "已绑定"},
                {value: "terminated", label: "已终止"}
            ],
            minerSearch:{

            },
            providerList:[],
            registerMiner:{
                deviceId:undefined,
                ownerAddress:undefined,
                expired_time:undefined,
                regionName:undefined
            },
            countryList : [
                {value: "Afghanistan",label: "Afghanistan"},
                {value: "Albania",label: "Albania"},
                {value: "Algeria",label: "Algeria"},
                {value: "Andorra",label: "Andorra"},
                {value: "Angola",label: "Angola"},
                {value: "Antarctica",label: "Antarctica"},
                {value: "Antigua and Barbuda",label: "Antigua and Barbuda"},
                {value: "Argentina",label: "Argentina"},
                {value: "Armenia",label: "Armenia"},
                {value: "Ashmore and Cartier Islands",label: "Ashmore and Cartier Islands"},
                {value: "Australia",label: "Australia"},
                {value: "Indian Ocean Territories",label: "Indian Ocean Territories"},
                {value: "Heard Island and McDonald Islands",label: "Heard Island and McDonald Islands"},
                {value: "Norfolk Island",label: "Norfolk Island"},
                {value: "Austria",label: "Austria"},
                {value: "Azerbaijan",label: "Azerbaijan"},
                {value: "Bahrain",label: "Bahrain"},
                {value: "Bangladesh",label: "Bangladesh"},
                {value: "Barbados",label: "Barbados"},
                {value: "Belarus",label: "Belarus"},
                {value: "Belgium",label: "Belgium"},
                {value: "Belize",label: "Belize"},
                {value: "Benin",label: "Benin"},
                {value: "Bhutan",label: "Bhutan"},
                {value: "Bolivia",label: "Bolivia"},
                {value: "Bosnia and Herzegovina",label: "Bosnia and Herzegovina"},
                {value: "Botswana",label: "Botswana"},
                {value: "Brazil",label: "Brazil"},
                {value: "Brunei",label: "Brunei"},
                {value: "Bulgaria",label: "Bulgaria"},
                {value: "Burkina Faso",label: "Burkina Faso"},
                {value: "Burundi",label: "Burundi"},
                {value: "Cambodia",label: "Cambodia"},
                {value: "Cameroon",label: "Cameroon"},
                {value: "Canada",label: "Canada"},
                {value: "Cape Verde",label: "Cape Verde"},
                {value: "Central African Republic",label: "Central African Republic"},
                {value: "Chad",label: "Chad"},
                {value: "Chile",label: "Chile"},
                {value: "China",label: "China"},
                {value: "Hong Kong S.A.R.",label: "Hong Kong S.A.R."},
                {value: "Macao S.A.R",label: "Macao S.A.R"},
                {value: "Colombia",label: "Colombia"},
                {value: "Comoros",label: "Comoros"},
                {value: "Costa Rica",label: "Costa Rica"},
                {value: "Croatia",label: "Croatia"},
                {value: "Cuba",label: "Cuba"},
                {value: "Cyprus",label: "Cyprus"},
                {value: "Czech Republic",label: "Czech Republic"},
                {value: "Democratic Republic of the Congo",label: "Democratic Republic of the Congo"},
                {value: "Denmark",label: "Denmark"},
                {value: "Greenland",label: "Greenland"},
                {value: "Faroe Islands",label: "Faroe Islands"},
                {value: "Djibouti",label: "Djibouti"},
                {value: "Dominica",label: "Dominica"},
                {value: "Dominican Republic",label: "Dominican Republic"},
                {value: "East Timor",label: "East Timor"},
                {value: "Ecuador",label: "Ecuador"},
                {value: "Egypt",label: "Egypt"},
                {value: "El Salvador",label: "El Salvador"},
                {value: "Equatorial Guinea",label: "Equatorial Guinea"},
                {value: "Eritrea",label: "Eritrea"},
                {value: "Estonia",label: "Estonia"},
                {value: "Ethiopia",label: "Ethiopia"},
                {value: "Federated States of Micronesia",label: "Federated States of Micronesia"},
                {value: "Fiji",label: "Fiji"},
                {value: "Aland",label: "Aland"},
                {value: "Finland",label: "Finland"},
                {value: "French Southern and Antarctic Lands",label: "French Southern and Antarctic Lands"},
                {value: "Saint Barthelemy",label: "Saint Barthelemy"},
                {value: "France",label: "France"},
                {value: "Wallis and Futuna",label: "Wallis and Futuna"},
                {value: "Saint Martin",label: "Saint Martin"},
                {value: "Saint Pierre and Miquelon",label: "Saint Pierre and Miquelon"},
                {value: "New Caledonia",label: "New Caledonia"},
                {value: "French Polynesia",label: "French Polynesia"},
                {value: "Gabon",label: "Gabon"},
                {value: "Gambia",label: "Gambia"},
                {value: "Georgia",label: "Georgia"},
                {value: "Germany",label: "Germany"},
                {value: "Ghana",label: "Ghana"},
                {value: "Greece",label: "Greece"},
                {value: "Grenada",label: "Grenada"},
                {value: "Guatemala",label: "Guatemala"},
                {value: "Guinea",label: "Guinea"},
                {value: "Guinea Bissau",label: "Guinea Bissau"},
                {value: "Guyana",label: "Guyana"},
                {value: "Haiti",label: "Haiti"},
                {value: "Honduras",label: "Honduras"},
                {value: "Hungary",label: "Hungary"},
                {value: "Iceland",label: "Iceland"},
                {value: "India",label: "India"},
                {value: "Indonesia",label: "Indonesia"},
                {value: "Iran",label: "Iran"},
                {value: "Iraq",label: "Iraq"},
                {value: "Ireland",label: "Ireland"},
                {value: "Israel",label: "Israel"},
                {value: "Palestine",label: "Palestine"},
                {value: "Italy",label: "Italy"},
                {value: "Ivory Coast",label: "Ivory Coast"},
                {value: "Jamaica",label: "Jamaica"},
                {value: "Japan",label: "Japan"},
                {value: "Jordan",label: "Jordan"},
                {value: "Siachen Glacier",label: "Siachen Glacier"},
                {value: "Kazakhstan",label: "Kazakhstan"},
                {value: "Kenya",label: "Kenya"},
                {value: "Kiribati",label: "Kiribati"},
                {value: "Kosovo",label: "Kosovo"},
                {value: "Kuwait",label: "Kuwait"},
                {value: "Kyrgyzstan",label: "Kyrgyzstan"},
                {value: "Laos",label: "Laos"},
                {value: "Latvia",label: "Latvia"},
                {value: "Lebanon",label: "Lebanon"},
                {value: "Lesotho",label: "Lesotho"},
                {value: "Liberia",label: "Liberia"},
                {value: "Libya",label: "Libya"},
                {value: "Liechtenstein",label: "Liechtenstein"},
                {value: "Lithuania",label: "Lithuania"},
                {value: "Luxembourg",label: "Luxembourg"},
                {value: "Macedonia",label: "Macedonia"},
                {value: "Madagascar",label: "Madagascar"},
                {value: "Malawi",label: "Malawi"},
                {value: "Malaysia",label: "Malaysia"},
                {value: "Maldives",label: "Maldives"},
                {value: "Mali",label: "Mali"},
                {value: "Malta",label: "Malta"},
                {value: "Marshall Islands",label: "Marshall Islands"},
                {value: "Mauritania",label: "Mauritania"},
                {value: "Mauritius",label: "Mauritius"},
                {value: "Mexico",label: "Mexico"},
                {value: "Moldova",label: "Moldova"},
                {value: "Monaco",label: "Monaco"},
                {value: "Mongolia",label: "Mongolia"},
                {value: "Montenegro",label: "Montenegro"},
                {value: "Morocco",label: "Morocco"},
                {value: "Mozambique",label: "Mozambique"},
                {value: "Myanmar",label: "Myanmar"},
                {value: "Namibia",label: "Namibia"},
                {value: "Nauru",label: "Nauru"},
                {value: "Nepal",label: "Nepal"},
                {value: "Aruba",label: "Aruba"},
                {value: "Curaçao",label: "Curaçao"},
                {value: "Netherlands",label: "Netherlands"},
                {value: "Sint Maarten",label: "Sint Maarten"},
                {value: "Cook Islands",label: "Cook Islands"},
                {value: "New Zealand",label: "New Zealand"},
                {value: "Niue",label: "Niue"},
                {value: "Nicaragua",label: "Nicaragua"},
                {value: "Niger",label: "Niger"},
                {value: "Nigeria",label: "Nigeria"},
                {value: "North Korea",label: "North Korea"},
                {value: "Northern Cyprus",label: "Northern Cyprus"},
                {value: "Norway",label: "Norway"},
                {value: "Oman",label: "Oman"},
                {value: "Pakistan",label: "Pakistan"},
                {value: "Palau",label: "Palau"},
                {value: "Panama",label: "Panama"},
                {value: "Papua New Guinea",label: "Papua New Guinea"},
                {value: "Paraguay",label: "Paraguay"},
                {value: "Peru",label: "Peru"},
                {value: "Philippines",label: "Philippines"},
                {value: "Poland",label: "Poland"},
                {value: "Portugal",label: "Portugal"},
                {value: "Qatar",label: "Qatar"},
                {value: "Republic of Congo",label: "Republic of Congo"},
                {value: "Republic of Serbia",label: "Republic of Serbia"},
                {value: "Romania",label: "Romania"},
                {value: "Russia",label: "Russia"},
                {value: "Rwanda",label: "Rwanda"},
                {value: "Saint Kitts and Nevis",label: "Saint Kitts and Nevis"},
                {value: "Saint Lucia",label: "Saint Lucia"},
                {value: "Saint Vincent and the Grenadines",label: "Saint Vincent and the Grenadines"},
                {value: "Samoa",label: "Samoa"},
                {value: "San Marino",label: "San Marino"},
                {value: "Sao Tome and Principe",label: "Sao Tome and Principe"},
                {value: "Saudi Arabia",label: "Saudi Arabia"},
                {value: "Senegal",label: "Senegal"},
                {value: "Seychelles",label: "Seychelles"},
                {value: "Sierra Leone",label: "Sierra Leone"},
                {value: "Singapore",label: "Singapore"},
                {value: "Slovakia",label: "Slovakia"},
                {value: "Slovenia",label: "Slovenia"},
                {value: "Solomon Islands",label: "Solomon Islands"},
                {value: "Somalia",label: "Somalia"},
                {value: "Somaliland",label: "Somaliland"},
                {value: "South Africa",label: "South Africa"},
                {value: "South Korea",label: "South Korea"},
                {value: "South Sudan",label: "South Sudan"},
                {value: "Spain",label: "Spain"},
                {value: "Sri Lanka",label: "Sri Lanka"},
                {value: "Sudan",label: "Sudan"},
                {value: "Suriname",label: "Suriname"},
                {value: "Swaziland",label: "Swaziland"},
                {value: "Sweden",label: "Sweden"},
                {value: "Switzerland",label: "Switzerland"},
                {value: "Syria",label: "Syria"},
                {value: "Tajikistan",label: "Tajikistan"},
                {value: "Thailand",label: "Thailand"},
                {value: "The Bahamas",label: "The Bahamas"},
                {value: "Togo",label: "Togo"},
                {value: "Tonga",label: "Tonga"},
                {value: "Trinidad and Tobago",label: "Trinidad and Tobago"},
                {value: "Tunisia",label: "Tunisia"},
                {value: "Turkey",label: "Turkey"},
                {value: "Taiwan",label: "Taiwan"},
                {value: "Turkmenistan",label: "Turkmenistan"},
                {value: "Uganda",label: "Uganda"},
                {value: "Ukraine",label: "Ukraine"},
                {value: "United Arab Emirates",label: "United Arab Emirates"},
                {value: "Anguilla",label: "Anguilla"},
                {value: "Bermuda",label: "Bermuda"},
                {value: "Falkland Islands",label: "Falkland Islands"},
                {value: "United Kingdom",label: "United Kingdom"},
                {value: "Guernsey",label: "Guernsey"},
                {value: "Isle of Man",label: "Isle of Man"},
                {value: "Cayman Islands",label: "Cayman Islands"},
                {value: "Jersey",label: "Jersey"},
                {value: "British Indian Ocean Territory",label: "British Indian Ocean Territory"},
                {value: "Montserrat",label: "Montserrat"},
                {value: "Pitcairn Islands",label: "Pitcairn Islands"},
                {value: "South Georgia and South Sandwich Islands",label: "South Georgia and South Sandwich Islands"},
                {value: "Saint Helena",label: "Saint Helena"},
                {value: "British Virgin Islands",label: "British Virgin Islands"},
                {value: "Turks and Caicos Islands",label: "Turks and Caicos Islands"},
                {value: "United Republic of Tanzania",label: "United Republic of Tanzania"},
                {value: "American Samoa",label: "American Samoa"},
                {value: "Guam",label: "Guam"},
                {value: "Northern Mariana Islands",label: "Northern Mariana Islands"},
                {value: "Puerto Rico",label: "Puerto Rico"},
                {value: "United States of America",label: "United States of America"},
                {value: "United States Virgin Islands",label: "United States Virgin Islands"},
                {value: "Uruguay",label: "Uruguay"},
                {value: "Uzbekistan",label: "Uzbekistan"},
                {value: "Vanuatu",label: "Vanuatu"},
                {value: "Vatican",label: "Vatican"},
                {value: "Venezuela",label: "Venezuela"},
                {value: "Vietnam",label: "Vietnam"},
                {value: "Western Sahara",label: "Western Sahara"},
                {value: "Yemen",label: "Yemen"},
                {value: "Zambia",label: "Zambia"},
                {value: "Zimbabwe",label: "Zimbabwe"},
        ]
        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onMinerAddressChange = this.onMinerAddressChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchMiner = this.SearchMiner.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.registerMiner = this.registerMiner.bind(this)
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this)
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this)
        this.handleRegisterOk = this.handleRegisterOk.bind(this)
        this.handleOnboardCancel = this.handleOnboardCancel.bind(this)
        this.handleOnboardOk = this.handleOnboardOk.bind(this)
        this.onOwnerAddressChange = this.onOwnerAddressChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this)
        this.onRegionChange = this.onRegionChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryMinerList(minerSearch){
        console.log(minerSearch.offset)
        let minerList = await SocketMinerApi.queryMinerList(minerSearch)
        return minerList;
    }
    async check() {
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 300,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Device Id',
                dataIndex: 'minerDeviceId',
                key: 'minerDeviceId',
                width: 300,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Miner Type',
                dataIndex: 'miner_type',
                width: 100,
                key: 'miner_type',
                render: (text) => {return <Tag color={'green'} key={"socketMiner"}>{'socketMiner'}</Tag>},
                
            },
            {
                title: 'Status',
                key: 'status',
                width: 100,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === 'orderable'){
                        color = '#1677ff'
                        text = '已注册'
                    }
                    if(status === 'onboarded'){
                        color = 'green'
                        text = '已绑定'
                    }
                    if(status === 'terminated'){
                        color = 'red'
                        text = '已终止'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
                width: 300,
                ellipsis:true,
                key: 'owner',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 300,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status==='onboarded'||record.status==='terminated')?"":<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleShowEdit(record)}}>
                                Onboard
                            </Button>

                            
                        }

                        {
                            (record.status==='orderable')?
                            <Button size='small' type="primary" icon={<DeleteOutlined />} onClick={()=>{
                                Modal.confirm({
                                title: 'Are you sure delete miner',
                                icon: <ExclamationCircleOutlined />,
                                content: record.name,
                                okText: 'Yes',
                                cancelText: 'Cancel',
                                onOk:()=>{this.handleDelete(record)}
                                    });
                                }}>
                                Delete
                                </Button>
                                :""
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryMinerList(this.state.minerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                let minerSearch = this.state.minerSearch
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    if(minerSearch.status && minerSearch.status !== item.status){
                        continue;
                    }
                    if(minerSearch.address && minerSearch.address !== item.address){
                        continue;
                    }
                    const plant = {
                        key:item.address,
                        minerDeviceId:item.mac,
                        address:item.address,
                        miner_type:item.miner_type,
                        owner:item.owner,
                        // create_time:new Date(item.createTime * 1000).toLocaleDateString()  ,
                        create_time:item.createTime  ,
                        status:item.status
                    }
                    data.push(plant)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else {
            this.setState({plantList:undefined})
        }
    }
    onStatusChange(value){
        let minerSearch = this.state.minerSearch
        minerSearch.status = value
        this.setState({minerSearch:minerSearch})
    }
    onMinerAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.address = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.offset = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchMiner()
    }
    onDeviceIdChange(e){
        let registerMiner = this.state.registerMiner
        console.log(registerMiner);
        registerMiner.deviceId = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onRegionChange(value){
        let registerMiner = this.state.registerMiner
        if(!value || value.length == 0){
            error.message('region error')
            registerMiner.regionName = undefined
            this.setState({registerMiner:registerMiner})
            return;
        }
        const region = value[0]
        registerMiner.regionName = region
        this.setState({registerMiner:registerMiner})
    }

    onMinerExpiredTimeChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.expired_time = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onOwnerAddressChange(e){
        let registerMiner = this.state.registerMiner
        console.log(registerMiner);
        registerMiner.ownerAddress = e.target.value
        this.setState({registerMiner:registerMiner})
    }
    SearchMiner(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    handleEditOk(){
        const editMinerInfo = this.state.editMinerInfo;
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        if(!editMinerInfo.address || editMinerInfo.address.length !== 42){
            message.error("Miner信息异常，请刷新页面后重试！")
            return;
        }
        if(!editMinerInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        if(!editMinerInfo.price.amount){
            message.error("过期天数不能为空！")
            return;
        }
        if(Number(editMinerInfo.price.amount)<=0){
            message.error("请输入正确的过期天数！")
            return;
        }
        if(!editMinerInfo.price.currency){
            message.error("请选择用于支付的货币！")
            return;
        }
        //
        RemoteMinerApi.updateMiner(editMinerInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("Miner信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }

    handleOnboardOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.ownerAddress){
            message.error("请输入Miner 钱包地址！")
            return;
        }
        if(!ethers.utils.isAddress(registerMiner.ownerAddress)){
            message.error("请输入正确的钱包地址！")
            return;
        }

        SocketMinerApi.onboardMiners(registerMiner.deviceId,registerMiner.ownerAddress).then(resp => {
            if(!resp.error){
                message.success("Standard Miner onboard成功！")
                this.setState({showOnboard:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })

    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onEditExpiredTimeChange(e){
        let editMinerInfo = this.state.editMinerInfo
        editMinerInfo.expired_time = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    handleShowEdit(record){
        // let editMinerInfo = this.state.editMinerInfo;
        // editMinerInfo.address = record.address
        // editMinerInfo.expired_time = Math.round(record.expired_time/86400)
        // editMinerInfo.price = record.priceDetail
        // this.setState({editMinerInfo:editMinerInfo})
        // console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        
        // this.setState({showEdit:true})
        let registerMiner = this.state.registerMiner
        registerMiner.deviceId = record.minerDeviceId
        this.setState({registerMiner:registerMiner})
        this.setState({showOnboard:true})
    }

    handleDelete(record){

        if(!record.minerDeviceId){
            message.error("Miner所属的deviceId 为空！")
            return;
        }
        this.setState({confirmLoading:true})
        SocketMinerApi.deleteMiner(record.minerDeviceId).then(resp => {
            if(!resp.error){
                message.success("Socket Miner删除成功！")
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    onCurrencyChange(value){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.currency = value
        this.setState({editMinerInfo:editMinerInfo})
    }

    registerMiner(){
        this.setState({showRegister:true})
    }
    handleRegisterOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.deviceId){
            message.error("请输入Miner所属的deviceId！")
            return;
        }
        if(!registerMiner.expired_time){
            message.error("请输入Miner所属的expired_time！")
            return;
        }
        this.setState({confirmLoading:true})
        SocketMinerApi.registerMiners(registerMiner).then(resp => {
            if(!resp.error){
                message.success("Standard Miner注册成功！")
                this.setState({showRegister:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    handleRegisterCancel(){
        this.setState({showRegister:false})
    }

    handleOnboardCancel(){
        this.setState({showOnboard:false})
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Miner Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Address:<Input onChange={this.onMinerAddressChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Status:
                            <Select size='small' style={{width:150}} placeholder="Select a status" onChange={this.onStatusChange} allowClear options={this.state.statusList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiner} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerMiner} icon={<PlusCircleOutlined />}>Register Miner</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Register Miner" open={this.state.showRegister} destroyOnClose={true} onOk={this.handleRegisterOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Device Id:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onDeviceIdChange} /></Col>
                        }
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Region:</span>
                        </Col>
                        <Col span={20}>
                            <Select mode="tags" style={{width:'100%'}} placeholder={'region name'} onChange={this.onRegionChange} allowClear options={this.state.countryList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>

                <Modal width='800px' title="Onboard Miner" open={this.state.showOnboard} destroyOnClose={true} onOk={this.handleOnboardOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleOnboardCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Owner Address:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onOwnerAddressChange} /></Col>
                        }
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default SocketMiner;
