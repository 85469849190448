import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import commonUtils from '../../../utils/commonUtils'
import PlantMinerApi from "../../../api/PlantMinerApi"
import PlantServiceApi from "../../../api/PlantServiceApi"
import RemoteMinerApi from "../../../api/remoteMinerApi"
import * as ethers from 'ethers'
import {getFilters} from "../../../utils/browserUtils";
import axios from "axios";
const { TextArea } = Input;





class EdrMiner extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            minerSearch:{},
            createClientInfo:{},
            showCreateClient:false,
            confirmLoading:false,
            clientRecords:[],
            
        };
        this.check = this.check.bind(this)
        this.onUserNameChange = this.onUserNameChange.bind(this)
        this.registerClient = this.registerClient.bind(this)
        this.SearchClient = this.SearchClient.bind(this)
        this.handleCreateClientCancel = this.handleCreateClientCancel.bind(this)
        this.handleCreateClientOk = this.handleCreateClientOk.bind(this)
        this.onCreateClientNameChange = this.onCreateClientNameChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)

    }
    async componentWillMount() {
        await this.check();
    }
    
    async check() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Client Id',
                dataIndex: 'clientId',
                key: 'clientId',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Token',
                dataIndex: 'token',
                width: 200,
                key: 'token',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 100,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            }
        ];
        this.setState({columns:columns})
        // 查询电站列表
        const clientRecords = await this.queryAllClientRecords()
        if(!clientRecords.error){
            const result = clientRecords.result
            if(!result){
                this.setState({clientRecords:[],totalCount:0})
                return;
            }
            const totalCount = result.total;
            const records = result.records;
            this.setState({totalCount:totalCount,clientRecords:records})
        }else{
            this.setState({clientRecords:[],totalCount:0})
        }
    }

    async queryAllClientRecords(){
        let clientRecords = await PlantServiceApi.edr_queryAllClientRecords(this.state.minerSearch)
        return clientRecords;
    }

    onUserNameChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.address = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    
    SearchClient(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    registerClient(){
        this.setState({showCreateClient:true})
    }
    handleCreateClientCancel(){
        this.setState({showCreateClient:false})
    }
    async handleCreateClientOk(){
        const createClientInfo = this.state.createClientInfo
        const name = createClientInfo.name
        if(!name){
            message.error("请输入客户端名称！")
            return;
        }
        this.setState({confirmLoading:true});
        const clientRecords = await PlantServiceApi.edr_createClient(createClientInfo)
        if(!clientRecords.error){
            message.success("客户端创建成功！")
            this.setState({showCreateClient:false})
            this.SearchClient()
        }else{
            message.error(clientRecords.error.message)
        }
        this.setState({confirmLoading:false});
    }
    onCreateClientNameChange(e){
        let createClientInfo = this.state.createClientInfo
        createClientInfo.name = e.target.value
        this.setState({createClientInfo:createClientInfo})
    }
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.page = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchClient()
    }



    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Miner Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Name:<Input onChange={this.onUserNameChange} size='small' style={{width:180}} placeholder="user name" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchClient} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerClient} icon={<PlusCircleOutlined />}>Register Client</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.clientRecords} />
                </div>
                <Modal width='800px' title="Create Client" open={this.state.showCreateClient} destroyOnClose={true} onOk={this.handleCreateClientOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleCreateClientCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Name:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onCreateClientNameChange} /></Col>
                        }
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default EdrMiner;
